import React, { useState, useRef, useLayoutEffect  } from 'react'

const Fader = () => {

    const [fadeProp, setFadeProp] = useState({
        animate: true,
    });

    useLayoutEffect(() => {
        fadeIn.current();
      }, []);

    const fadeIn = useRef(() => {
        //console.log('fade-in');
        setFadeProp({ animate: false})
        const id = setInterval(() => {
            if(fadeProp.animate) clearInterval(id);
            fadeOut();
        }, 1500);
    });

    function fadeOut() {
        //console.log('fade-out');
        setFadeProp({ animate: true})
    }

    return (
        <>
            <div id="helpDiv"><img src={process.env.PUBLIC_URL + '/images/help.gif'} className={fadeProp.animate?'fade-out':'fade-in'} alt="logo" onClick={fadeOut}></img></div>
        </>
    )
}

export default Fader